<template>
  <div>
    <list
      ref="refTSoftwareList"
      sort-by="SoftwareId"
      refetch-records-name="softwares"
      key-field="SoftwareId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchSoftwares"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'

export default {
  components: {
    List,
  },
  setup() {
    const refSoftwareList = ref(null)
    const { fetchSoftwares } = tiService()
    const filters = ref([
      {
        type: 'select',
        name: 'proveedorTiId',
        label: i18n.t('AppTI.filters.provider'),
        value: '',
        options: [],
        domainName: 'proveedoresTi',
      },
    ])
    const tableColumns = [

      {
        key: 'softwareId',
        label: i18n.t('AppTI.columns.id'),
        sortable: true,
        sortKey: 'SoftwareId',
      },
      {
        key: 'nombre',
        label: i18n.t('AppTI.columns.name'),
        sortable: false,
      },
      {
        key: 'proveedorTi.nombre',
        label: i18n.t('AppTI.filters.provider'),
        sortable: false,
      },
      {
        key: 'licenciamientoTipo.nombre',
        label: i18n.t('AppTI.columns.subscription'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Details'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-software-details',
        params: ['softwareId'],
        icon: 'CodesandboxIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('AppTI.actions.newSoftware'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-software-add',
        icon: 'PlusCircleIcon',
      },
    ])
    return {
      refSoftwareList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fetchSoftwares,
    }
  },
}

</script>
